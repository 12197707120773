
@import "~@ng-select/ng-select/themes/default.theme.css";
*{
    font-family: 'Poppins', sans-serif !important;
    margin: 0;
    padding: 0;
    text-transform:none !important;
    letter-spacing:0 !important;
}

*{
    &::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #161b30;
    }
    &::-webkit-scrollbar
    {
        width: 8px;
        height: 8px;
        background-color: #161b30;
    }

    &::-webkit-scrollbar-thumb
    {
        background-color: #f5f5f5;
    }
}

html,body{
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    background: linear-gradient(to right,#151A2E, #222A48);
    overflow: hidden;
}

.logo{
    font-size: 49px;
    font-style: normal;
    font-weight: 500;
    line-height: 74px;
    letter-spacing: 1.6961538791656494px;
    color: #EFEFEF;
}
.alert{
    width: 34%;
    top: 30px;
    right: 30px;
    position: absolute;
    font-size: 14px;
    display: flex;
    align-items: center;
    span{
        margin-right: 20px;
        font-size: 14px;
        width: calc(100% - 20px);
    }
    .close{
        float: none;
        opacity: 1;
        cursor: pointer;
        color: inherit;
        svg{
            width: 14px;
            height: 14px;
        }
    }
}
button:disabled{
    cursor:not-allowed;
}

.grey_but{
    background-color: gray !important;
    border:2px solid gray!important;
}

.hori_center{
    display: flex;
    width: 100%;
    justify-content: center;
}

.vert_center{
    display: flex;
    height: 100%;
    align-content: center;
}

@media screen and (max-width:1024px) {
    html, body {
        overflow-y: scroll;

    }
    html {
        margin-bottom: 70px;
        /*height: 110%;*/
    }
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

::ng-deep .title .mat-form-field-flex {
    height: 10px !important;
}

::ng-deep .title .mat-form-field {
    height: 0px !important;
}
